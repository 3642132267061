<template>
    <v-container>
        <v-row>
            <v-col>

                <v-card>
                    <v-card-title>
                        {{ $t('remarks') }}

                        <v-spacer></v-spacer>

                        <v-text-field
                                id="searchField"
                                v-model="search"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                        ></v-text-field>
                    </v-card-title>

                    <v-data-table
                            id="dataTable"
                            :headers="headers"
                            :items="remarks"
                            :search="search"
                            :items-per-page="15"
                            @click:row="onClickRow"
                    ></v-data-table>
                </v-card>

            </v-col>
        </v-row>

        <v-dialog id="dialog" v-model="dialog" persistent width="75%">
            <v-card>
                <v-form @submit.prevent="save">
                    <v-card-title>{{ $t('remark_details') }}</v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <v-img :src="'previews/' + remarkDetails.preview"></v-img>
                            </v-col>

                            <v-col cols="6">
                                <v-card-text>
                                    <div>{{ remarkDetails.image_name }}</div>
                                    <div>{{ remarkDetails.user_name }}</div>

                                    <v-divider class="mt-8 mb-8"></v-divider>
                                    <div>{{ remarkDetails.remark }}</div>
                                    <v-divider class="mt-8 mb-8"></v-divider>

                                    <v-autocomplete
                                            id="priorityField"
                                            :label="$t('priority')"
                                            :items="getPriority"
                                            :value="remarkDetails.priority"
                                            v-model="remarkDetails.priority"
                                    >
                                    </v-autocomplete>

                                    <v-autocomplete
                                            id="statusField"
                                            :label="$t('status')"
                                            :items="getRemark"
                                            :value="remarkDetails.status"
                                            v-model="remarkDetails.status"
                                    >
                                    </v-autocomplete>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn id="cancelButton" color="error"
                               @click="dialog = false"
                               v-shortkey="['esc']" @shortkey="dialog = false"
                        >
                            <v-icon dark left>mdi-cancel</v-icon>
                            {{ $t('cancel') }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn id="submitButton" type="submit" color="info">
                            <v-icon dark left>mdi-send</v-icon>
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>

                </v-form>
            </v-card>
        </v-dialog>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import remarkService from '@/services/RemarkService.js'

    export default {
        name: "Remarks",

        computed: {
            ...mapState(['isAdmin']),
            ...mapGetters(['getRemark', 'getPriority']),
        },

        created() {
            // Route guard
            if (!this.isAdmin) {
                this.$store.dispatch('logUserOut')
                this.$router.push('/login')
            }

            this.init()
        },

        data() {
            return {
                headers: [
                    {text: this.$t('id'), value: 'id'},
                    {text: this.$t('image'), value: 'image_name'},
                    {text: this.$t('user'), value: 'user_name'},
                    {text: this.$t('remark'), value: 'short_remark'},
                    {text: this.$t('priority'), value: 'priority'},
                    {text: this.$t('status'), value: 'status'},
                ],
                remarks: [],
                search: '',

                remarkDetails: {
                    id: '',
                    image_name: '',
                    user_name: '',
                    remark: '',
                    short_remark: '',
                    priority: '',
                    status: '',
                    preview: '',
                },

                dialog: false,

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            init() {
                remarkService.all()
                    .then(response => {
                        this.remarks = response.data
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },

            onClickRow(rowData) {
                this.dialog = true

                this.remarkDetails = rowData
            },

            save() {
                this.dialog = false

                remarkService.save(this.remarkDetails)
                    .then(() => {
                        this.snackbar = true
                        this.snackbarColor = "success"
                        this.snackbarMessage = this.$t('data_saved')

                        this.init()
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },
        },
    }
</script>
